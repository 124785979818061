import { Controller } from 'stimulus'

const lunr = require("lunr")
require("lunr-languages/lunr.stemmer.support")(lunr)
require("lunr-languages/lunr.es")(lunr)

export default class extends Controller {
  static targets = [ 'q', 'item', 'container', 'form', 'noResults' ]

  get q () {
    if (!this.hasQTarget) return
    if (!this.qTarget.value.trim().length === 0) return

    return this.qTarget.value.trim()
  }

  get terms () {
    if (!this._terms) this._terms = this.element.dataset.terms.split(',')

    return this._terms
  }

  set formDisable (disable) {
    this.formTarget.elements.forEach(x => x.disabled = disable)
  }

  /*
   * Filtra la lista de artículos en base a los términos de búsqueda,
   * mostrando u ocultando lo que corresponda.
   */
  async filter (event) {
    // Detiene el envío del formulario
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formDisable = true

    let results = []

    // Obtiene el término de búsqueda
    const q = this.q
    // Si no hay término de búsqueda, no hay búsqueda
    if (q) {

      // Trae el índice de búsqueda
      await this.fetch()

      // Hasta que no haya índice no buscamos nada, esto evita que se
      // aprete enter dos veces y se fallen cosas.
      if (!window.index) return

      // Solo queremos una lista de IDs
      results = window.index.search(q).map(r => r.ref)
    }

    const values = {}

    for (const term of this.terms) {
      const value = this.formTarget.elements[term].value

      if (value.length === 0) continue

      values[term] = value
    }

    // Procesar los items para mostrar u ocultar los que correspondan
    for (const item of this.itemTargets) {
      // La lista de condiciones que cumple o no
      const matches = []

      // Está en los resultados
      if (results.length > 0) matches.push(results.includes(item.dataset.ref))

      for (const term in values) {
        matches.push(item.dataset[term].split('|').includes(values[term]))
      }

      // Quita o agrega la clase si cumple todas las condiciones
      //
      // El !visible es para negar (convertir en el valor opuesto)
      // porque d-none oculta items en lugar de mostrarlos.
      item.hidden = !(matches.length > 0 && matches.every(x => x))
    }

    const noResults = this.itemTargets.every(x => x.hidden)

    this.noResultsTarget.hidden = !noResults
    this.containerTarget.hidden = false
    this.containerTarget.scrollIntoView()
    this.formDisable = false
  }

  async fetch () {
    // Solo permite descargar uno a la vez
    if (this.fetching) return

    this.fetching = true
    let response

    // Si no existe el índice, lo descarga y procesa Lunr
    if (!window.index) {
      response = await fetch('idx.json')
      const idx = await response.json()
      window.index = lunr.Index.load(idx)
    }

    // Permitir volver a ejecutar
    this.fetching = false
  }
}
