import { Notifier } from '@airbrake/browser'

// Esto es para enviarnos informes de error a Sutty
if (window.env) {
  window.airbrake = new Notifier({
    projectId: window.env.AIRBRAKE_PROJECT_ID || 0,
    projectKey: window.env.AIRBRAKE_PROJECT_KEY || '',
    host: 'https://panel.sutty.nl'
  })
}

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo"
Turbo.start()

// StimulusJS.org
import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Acciones al terminar de cargar la página e iniciar Turbo.
document.addEventListener('turbo:load', event => {
  // Los links externos se abren por fuera de la app
  document.querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']").forEach(a => {
    if (a.href.startsWith(window.location.origin)) return

    a.rel = "noopener noreferrer"
    a.target = "_blank"
  })
})
