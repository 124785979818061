import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if (!window.localStorage.getItem(this.id)) this.element.hidden = false
  }

  close (event) {
    event.preventDefault()
    event.stopPropagation()

    window.localStorage.setItem(this.id, true)
    this.element.hidden = true
    this.element.querySelectorAll('video,audio').forEach(x => x.pause())
    this.element.querySelectorAll('iframe').forEach(x => x.src = '')
  }

  get id () {
    return `once-controller:${this.element.id}`
  }
}
