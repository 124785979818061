import { Controller } from 'stimulus'

/*
 * Se encarga de ir invitando a les usuaries a obtener la webapp.  El
 * objetivo es que la instalen, pero antes tenemos que poder detectar si
 * pueden.
 */
export default class extends Controller {
  static targets = [ 'internalBrowser', 'browser' ]

  connect () {
    this.deferredPrompt = null

    // Este evento se lanza cuando el sitio cumple todas las condiciones
    // para poder instalarse.  Entonces no tenemos que detectar nada,
    // solo esperar a este evento.
    //
    // https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen
    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault()

      // Que el botón pueda recibir el evento
      this.deferredPrompt = event
      // Mostrar el botón
      this.browserTarget.hidden = false
    })

    const isInternalBrowser = this.detectInternalBrowser()

    if (this.hasInternalBrowserTarget) {
      this.internalBrowserTarget.hidden = !isInternalBrowser
    }

    if (!isInternalBrowser && this.supportsServiceWorker)
      navigator.serviceWorker.register('sw.js')
  }

  // Detecta si le usuarie está en un navegador interno de una app, por
  // ejemplo Facebook, Instagram o Telegram.
  //
  // Si no tiene soporte para Service Workers mostramos el botón que
  // permite escapar del navegador.
  detectInternalBrowser () {
    // Si es un navegador interno
    if (this.isFacebook) return true
    if (this.isInstagram) return true
    if (this.isWhatsapp) return true
    if (this.isWebView) return true

    // Si no hay un Service Worker también lo ignoramos
    if (this.supportsServiceWorker) return false

    return true
  }

  // Instalar la app!
  //
  // TODO: Agregar alguna forma de saber cuántas personas la instalaron.
  // Una API de Sutty quizás?
  install (event) {
    event.preventDefault()
    event.stopPropagation()

    if (!this.deferredPrompt) return

    // Preguntarle a le usuarie si quiere instalar la app, siguiendo la
    // interfaz del navegador.
    this.deferredPrompt.prompt()

    // Esperar hasta que responda
    this.deferredPrompt.userChoice.then(result => {
      // Si ya la instaló ocultamos el botón.  Desde el sitio no tenemos
      // forma de saber si ya está instalada!
      this.browserTarget.hidden = (result.outcome === 'accepted')
      // Reiniciar el evento
      this.deferredPrompt = null
    })
  }

  get userAgent () {
    if (!this._userAgent) this._userAgent = (navigator.userAgent || navigator.vendor || window.opera)

    return this._userAgent
  }

  get isFacebook () {
    const ua = this.userAgent

    return (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1)
  }

  get isInstagram () {
    return (this.userAgent.indexOf("Instagram") > -1)
  }

  get isWhastapp () {
    return (this.userAgent.indexOf("Whastapp") > -1)
  }

  get isWebView () {
    return (this.userAgent.indexOf("wv") > -1)
  }

  get supportsServiceWorker () {
    return ("serviceWorker" in window.navigator)
  }
}
